<template>
    <Layout>
        <PageHeader :title="title" :items="routeItems" />
        <div class="row">
            <div class="col-lg-7">
                <div class="card rounded-3">
                    <div class="card-header card-header-fs">
                        {{ $t('sns.accountInfo') }}
                    </div>
                    <div class="card-body">
                        <div class="row px-4 gap-3">
                            <div class="col-lg-5">
                                <b-button v-if="!state.session.accessToken" :disabled="disabled" @click="connectTwitter"
                                    size="md" class="px-3" variant="primary">{{ $t('sns.connect') }}</b-button>
                                <b-button v-else @click="connectOtherAccount" :disabled="disabled" size="md" class="px-3"
                                    variant="success">{{
                                        $t('sns.twLinked') }}</b-button>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <div>
                                            <label>{{ $t('sns.accountName') }}</label>
                                            <input v-model="state.session.username" readonly type="text"
                                                class="form-control" :disabled="disabled" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card rounded-3">
                    <div class="card-header d-flex justify-content-between align-items-center card-header-fs">
                        {{ $t('sns.postInfo') }}
                        <b-button :disabled="!state.session.accessToken || state.setting.roomIds.length === 0 || disabled"
                            size="sm" class="px-3" variant="primary" @click="submitSNS">{{ $t('t-update')
                            }}</b-button>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row gap-3 px-4">
                                <div class="col-lg-12">
                                    <div class="col-md-10 col-lg-8">
                                        <label>{{ $t('sns.dayOfWeek') }}</label>
                                        <div class="input-group">
                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.mon') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="1" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.tue') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="2" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.wed') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="3" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.thu') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="4" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.fri') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="5" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.sat') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="6" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.sun') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="0" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column align-items-center pItem">
                                                <label>{{ $t('sns.holiday') }}</label>
                                                <div class="input-group-text pickDayCheckbox">
                                                    <input v-model="state.setting.days" class="form-check-input mt-0"
                                                        type="checkbox" :value="7" :disabled="disabled"
                                                        aria-label="Checkbox for following text input">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="col-lg-6">
                                        <label>{{ $t('sns.postTime') }} <span class="text-danger">*</span></label>
                                        <div class="d-flex gap-4">
                                            <div>
                                                <FlatPickr v-model="state.setting.startTime" :disabled="disabled"
                                                    placeholder="00:00" :config="timeStartConfig" class="form-control" />
                                                <div v-if="v$.startTime.$error" class="invalid-feedback d-flex flex-column">
                                                    <span v-if="v$.startTime.required.$invalid">{{
                                                        v$.startTime.required.$message
                                                    }}</span>
                                                </div>
                                            </div>
                                            <span class="pt-2">~</span>
                                            <div>
                                                <FlatPickr v-model="state.setting.endTime" :disabled="disabled"
                                                    placeholder="23:59" :config="timeEndConfig" class="form-control" />
                                                <div v-if="v$.endTime.$error" class="invalid-feedback d-flex flex-column">
                                                    <span v-if="v$.endTime.required.$invalid">{{
                                                        v$.endTime.required.$message
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="col-lg-7">
                                        <label>{{ $t('sns.repetition') }}</label>
                                        <v-select v-model="state.setting.durationTime" :disabled="disabled"
                                            :options="options" :reduce="repeat => repeat.value"
                                            :placeholder="$t('sns.pleaseSelectPostTimes')">
                                            <template #no-options="{ search, searching, loading }">{{ $t('t-no-data')
                                            }}</template>
                                        </v-select>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <label>{{ $t('sns.saunaRoomSituation') }} <span class="text-danger">*</span></label>
                                    <Multiselect @open="() => getRooms()" mode="tags" trackBy="label" :max="4"
                                        :close-on-select="false" v-model="state.setting.roomIds" :searchable="true"
                                        class="custom-multiselect" :placeholder="$t('sns.pleaseSelectRoom')"
                                        :options="state.rooms" :disabled="disabled">
                                        <template #noresults>
                                            <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                                        </template>
                                        <template #nooptions>
                                            <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                                        </template>
                                    </Multiselect>
                                    <div v-if="v$.roomIds.$error" class="invalid-feedback d-flex flex-column">
                                        <span v-if="v$.roomIds.required.$invalid">{{
                                            v$.roomIds.required.$message
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="card rounded-3">
                    <div class="card-header d-flex justify-content-between align-items-center card-header-fs">
                        {{ $t('sns.preview') }}
                        <b-button
                            :disabled="state.setting.roomIds.length === 0 || state.session.accessToken === '' || disabled"
                            size="sm" class="px-3" variant="primary" @click="manualPost">{{ $t('sns.manual')
                            }}</b-button>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="position-relative post">
                                <div class="pInfo">
                                    <div class="d-flex flex-column justify-content-between gap-3 h-100">
                                        <div class="text-center">
                                            <h4 :title="state.facility.name" class="title text-truncate w-100 px-4">{{
                                                state.facility.name }}
                                            </h4>
                                            <p class="time m-0">{{ state.date }}</p>
                                        </div>
                                        <div class="row gx-5 gy-3 m-0 p-0">
                                            <div class="col-lg-4 facilityImage">
                                                <img v-if="state.facilityImage" :src="state.facilityImage"
                                                    alt="facilityImage" />
                                                <img v-else src="@/assets/images/no-image.jpg" alt="noImage" />
                                            </div>
                                            <div class="col-lg-8">
                                                <div :class="`row gy-2 w-100 pt-3 ${previewClass}`">
                                                    <div v-for="room of rooms" :key="room.value" class="col-lg-6">
                                                        <div class="d-flex gap-2">
                                                            <img width="26" height="29"
                                                                src="@/assets/images/sauna-room-icon.png" />
                                                            <div :title="room.roomSectionText" class="d-flex flex-column gap-2 w-100 pt-2">
                                                                <div class="roomConsistence text-truncate">{{
                                                                    room.roomSectionText }}
                                                                </div>
                                                                <label :class="room.className">{{ room.consistenceText
                                                                }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center gap-3 pt-3">
                                            <p class="text-powerby">Powered by</p>
                                            <img src="@/assets/images/logo/TS-Logo-2-wh.svg" alt="" height="25" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue';
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { snsService } from '@/services'
import FlatPickr from "vue-flatpickr-component";
import Multiselect from "@vueform/multiselect";
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import Swal from "sweetalert2";
import VSelect from 'vue-select';
import { useStore } from 'vuex';
import { facilityService } from '@/services';
import { cloneDeep, delay, extend, filter, isEqual, reduce, remove, round } from 'lodash';
import Cookie from 'js-cookie';
import { clientOSS } from '@/helpers/axios/formDataRequest';
import { useRouter } from 'vue-router';
import { ROLES } from '@/constants'

export default {
    components: {
        Layout,
        PageHeader,
        FlatPickr,
        Multiselect,
        VSelect
    },
    setup() {
        const store = useStore();
        const facilityId = computed(() => store.getters['settings/getFacilityId']);
        const oss = clientOSS
        const router = useRouter()
        const currentUser = computed(() => store.getters['auth/currentUser']);
        const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || !currentUser.value);

        const initDefault = {
            accessToken: '',
            facilityId: facilityId.value,
            id: '',
            refreshToken: '',
            username: '',
            startTime: null,
            endTime: null,
            isHoliday: false,
            days: [],
            durationTime: null,
            roomIds: []
        }

        const initDefaultFacility = {
            name: '',
            id: '',
            facilityIdStr: '',
            image: ''
        }

        const initDefaultSession = {
            accessToken: '',
            refreshToken: '',
            username: '',
        }

        const state = reactive({
            sns: null,
            setting: { ...initDefault },
            rooms: [],
            facility: { ...initDefaultFacility },
            date: moment().utc(true).format('YYYY/MM/DD HH:mm'),
            facilityImage: '',
            session: { ...initDefaultSession }
        })

        const options = [
            { label: '30 分', value: 30 },
            { label: '60 分', value: 60 },
            { label: '120 分', value: 120 },
            { label: '180 分', value: 180 }
        ];

        const rules = {
            startTime: {
                required: helpers.withMessage(i18n.global.t('sns.postTimeReq'), required)
            },
            endTime: {
                required: helpers.withMessage(i18n.global.t('sns.postTimeReq'), required)
            },
            roomIds: {
                required: helpers.withMessage(i18n.global.t('field_select_required', { field: i18n.global.t('sns.saunaRoomSituation') }), required)
            },
        }

        const setting = computed(() => state.setting)

        const v$ = useVuelidate(rules, setting)

        const timeStartConfig = computed(() => ({
            enableTime: true,
            dateFormat: "H:i",
            noCalendar: true,
            maxTime: state.setting.endTime
        }))

        const timeEndConfig = computed(() => ({
            enableTime: true,
            dateFormat: "H:i",
            noCalendar: true,
            minTime: state.setting.startTime
        }))

        const previewClass = computed(() => {
            if (state.setting.roomIds.length <= 2) return 'align-items-center h-100'
            else {
                return 'h-100'
            }
        })

        const rooms = computed(() => {
            if (state.setting.roomIds.length === 0) return []
            else {
                const order = reduce(state.setting.roomIds.map((r, idx) => ({ [r]: idx })), extend)
                const selectedRooms = filter(state.rooms, room => state.setting.roomIds.includes(room.value)).map(s => ({ ...s, pos: order[s.value] }))
                const maps = selectedRooms.sort((a, b) => a.pos - b.pos).map((room, idx) => {

                    const { currentPeopleCount, capacity } = room
                    const consistence = round((currentPeopleCount / capacity) * 100, 0);
                    let consistenceText = '';
                    let className = ''

                    if (consistence < 40 || Number.isNaN(consistence)) {
                        consistenceText = i18n.global.t('sns.consistenceEmpty')
                        className = 'labelStatus labelEmpty'
                    } else if (consistence >= 40 && consistence < 60) {
                        consistenceText = i18n.global.t('sns.consistenceNormal')
                        className = 'labelStatus labelNormal'
                    } else if (consistence >= 60 && consistence < 80) {
                        consistenceText = i18n.global.t('sns.consistenceMedium')
                        className = 'labelStatus labelMedium'
                    } else {
                        consistenceText = i18n.global.t('sns.consistenceLarge')
                        className = 'labelStatus labelLarge'
                    }

                    const roomTypeI18n = i18n.global.t(roomTypes[room.type])
                    const roomSectionText = `${roomTypeI18n}：${room.label || ''}`
                    return {
                        ...room,
                        consistence: Math.floor(room.currentPeopleCount / room.capacity) * 100,
                        consistenceText,
                        roomTypeI18n,
                        roomSectionText,
                        className,
                        idx
                    }
                })
                return maps;
            }
        })

        const routeItems = [
            {
                text: i18n.global.t('t-setting_').toUpperCase(),
                active: true,
            },
            {
                text: i18n.global.t('sns.management').toUpperCase(),
                active: true,
            },
        ];

        const title = i18n.global.t('sns.management');
        const holiday = 7
        const roomTypes = { 'SAUNA_ROOM_MALE': 'sns.saunaMen', 'SAUNA_ROOM_FEMALE': 'sns.saunaWomen' }

        const getRooms = async () => {
            if (!facilityId.value) return;
            try {
                const response = await facilityService.getRoomsOfFacility(facilityId.value)
                const data = response || []
                state.rooms = data.map(d => ({ label: d.name, value: d.value, type: d.type, capacity: d.capacity, currentPeopleCount: d.currentPeopleCount }))

            } catch (e) {
                console.log('getRooms', e)
            }
        }

        const connectTwitter = async () => {
            let domain = process.env.VUE_APP_SAUNA_URL;
            if (!domain.endsWith('/')) domain += '/'
            window.open(`${domain}web/posts/twitter/connect`, '_self');
        }

        const deleteCookie = (cookieName = 'profile_twitter') => {
            const domain = process.env.VUE_APP_ENV ? '' : '.tokyo-saunist.com'
            Cookie.remove(cookieName, {
                domain
            })
        }

        const connectOtherAccount = async () => {
            try {
                deleteCookie('profile_twitter')
                await snsService.deleteSnsSession(state.facility.id)
                state.session = {
                    ...initDefaultSession
                }
                delay(() => getSnsByFacilityId(state.setting.facilityId), 100)
            } catch (e) {
                state.session = {
                    ...initDefaultSession
                }
                console.log(e, 'connectOtherAccount')
            }
        }

        const cookieToState = () => {
            let profileString = parseProfileCookie('profile_twitter')
            if (profileString) {
                profileString = decodeURIComponent(profileString)
                const { access_token: accessToken, refresh_token: refreshToken, username } = JSON.parse(profileString)
                state.setting = { ...state.setting, accessToken, refreshToken, username }
                state.session = {
                    accessToken,
                    refreshToken,
                    username
                }
            } else {
                state.setting = { ...state.setting, accessToken: '', refreshToken: '', username: '' }
            }
        }

        const cookieToObject = () => {
            let profileString = parseProfileCookie('profile_twitter')
            if (profileString) {
                profileString = decodeURIComponent(profileString)
                const { access_token: accessToken, refresh_token: refreshToken, username } = JSON.parse(profileString)
                return {
                    accessToken,
                    refreshToken,
                    username
                }
            } else {
             return{ ...state.setting, accessToken: '', refreshToken: '', username: '' }
            }
        }

        const manualPost = async () => {
            try {
                if (state.setting.roomIds.length === 0) {
                    Swal.fire({
                        title: "",
                        text: i18n.global.t('field_select_required', { field: i18n.global.t('sns.saunaRoomSituation') }),
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return;
                }
                if (!facilityId.value) return;
                const userLastConnected = await snsService.getSnsByFacilityId(facilityId.value)
                if (userLastConnected) {
                    let { username, accessToken, refreshToken } = userLastConnected
                    state.session = {
                        username,
                        accessToken,
                        refreshToken
                    }
                }
                const response = await snsService.manualPost(facilityId.value, {
                    accessToken: state.session.accessToken,
                    refreshToken: state.session.refreshToken,
                    roomIds: state.setting.roomIds
                })
                if (response.result) {
                    Swal.fire({
                        title: "",
                        text: i18n.global.t('msg.manualPostSuccess'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
            } catch (e) {
                const { message = 'error', statusCode } = e.data
                if (statusCode === 404 && message.toLowerCase().includes('facility')) {
                    await toDashboard()
                }
                if (statusCode === 504) {
                    
                } else {
                    Swal.fire({
                        title: "",
                        text: message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
            }
        }

        const parseProfileCookie = (cookieName = 'profile_twitter') => {
            return Cookie.get(cookieName)
        }

        const getUserSnsSession = async () => {
            if (!facilityId.value) return;
            try {
                const response = await snsService.getSnsSession(facilityId.value)   
                if (response) {
                state.session = response ? response : { ...initDefaultSession }
                state.setting = { ...state.setting, ...response }
                }
            } catch (e) {
                console.log(e, 'getUserSnsSession');
            }
        }

        const responseToState = (response) => {
            let { days: orgDays = [], isHoliday, roomIds = [], startTime, endTime, durationTime, username, accessToken, refreshToken, id } = response
            let days = cloneDeep(orgDays)
            if (isHoliday) {
                days.push(holiday)
            } else {
                days = days.filter(d => d !== holiday)
            }
            if (isEqual(durationTime, 0)) durationTime = null
            state.setting = { days, isHoliday, roomIds, startTime, endTime, durationTime, username, accessToken, refreshToken, id };
            state.session = {
                username,
                accessToken,
                refreshToken
            }
        }

        const getSnsByFacilityId = async () => {
            try {
                state.session = {
                    username: '',
                    accessToken: '',
                    refreshToken: ''
                }
                if (!facilityId.value) return;
                const response = await snsService.getSnsByFacilityId(facilityId.value)
                if (response) {
                    const accountLoggedIn = cookieToObject()
                    if (parseProfileCookie() && response.username != accountLoggedIn.username) {
                        cookieToState()
                        const { id, ...rest } = state.setting
                        const res = await snsService.pushSnsPost(facilityId.value, rest)
                        if (res) {
                            responseToState(res)
                        }
                    } else
                        responseToState(response)

                }
            } catch (e) {
                state.setting = { ...initDefault }
                cookieToState()
                const { data: { statusCode, message = '' } } = e
                if (statusCode === 404 && message.toLowerCase().includes('facility')) {
                    await toDashboard()
                }

                if (statusCode === 404 && message.toLowerCase().includes('post') && parseProfileCookie()) {
                    const { id, ...rest } = state.setting
                    const res = await snsService.pushSnsPost(facilityId.value, rest)
                    if (res) {
                        responseToState(res)
                    }
                }
                console.log('getSnsByFacilityId', e);
            }
        }

        const getFacility = async () => {
            if (!facilityId.value) return;
            try {
                const response = await facilityService.getFacility(facilityId.value)
                if (response) {
                    state.facility = response;
                    if (state.facility.image) {
                        state.facilityImage = state.facility.image.startsWith('http') ? state.facility.image : oss.signatureUrl(state.facility.image)
                    }
                } else {
                    state.facility = { ...initDefaultFacility }
                    state.facilityImage = ''
                }
            } catch (e) {
                console.log('getFacility', e);
            }
        }

        const toDashboard = async () => {

            const accept = await Swal.fire(i18n.global.t('msg.sns_facility_not_assigned'));

            if (accept.value) {
                router.replace({ name: 'default' })
            }
        }

        const submitSNS = async () => {
            v$.value.$touch();
            if (v$.value.$invalid) return;
            if (!facilityId.value) return;

            try {
                const body = cloneDeep(state.setting)
                const { id, ...rest } = body
                if (rest.days.includes(holiday)) {
                    rest.isHoliday = true
                    rest.days = remove(rest.days, d => d !== holiday)
                } else {
                    rest.isHoliday = false
                }
                const userLastConnected = await snsService.getSnsByFacilityId(facilityId.value)
                if (userLastConnected) {
                    let { username, accessToken, refreshToken } = userLastConnected
                    state.session = {
                        username,
                        accessToken,
                        refreshToken
                    }
                }
                await snsService.pushSnsPost(facilityId.value, {
                    ...rest,
                    ...state.session
                })
                await Swal.fire({
                    title: "",
                    text: i18n.global.t('msg.saved'),
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                })
                state.date = moment().utc(true).format('YYYY/MM/DD HH:mm')
            } catch (e) {
                const { statusCode, message = '' } = e?.data
                if (statusCode === 404 && message.toLowerCase().includes('facility')) {
                    await toDashboard()
                }
                console.log('submitSNS', e);
            }
        }

        onMounted(() => {
            getRooms()
            getSnsByFacilityId()
            getFacility()
            getUserSnsSession()
        })

        watch(facilityId, () => {
            getRooms()
            getSnsByFacilityId()
            getFacility()
            getUserSnsSession()
            v$.value.$reset()
        })

        return {
            state,
            title,
            routeItems,
            connectTwitter,
            timeStartConfig,
            timeEndConfig,
            submitSNS,
            v$,
            connectOtherAccount,
            options,
            rooms,
            manualPost,
            getRooms,
            previewClass,
            disabled
        }
    }
}
</script>

<style scoped lang="scss">
[data-input-facility] {
    position: relative;
}

[data-input-facility] .dropdown-menu .dropdown-menu-list {
    max-height: 158px;
    overflow-y: auto;
}

[data-option-facility]:before {
    content: "\f0140";
    font-family: Material Design Icons;
    position: absolute;
    right: .9rem;
    top: .5rem;
    font-size: 16px;
}

.post-container {
    width: 368px;
    height: auto;
    padding: 10px 20px;

    & h5 {
        font-weight: bold;
    }

    & .image {
        height: 200px;
        width: 100%;
        overflow: hidden;
        border-radius: 5px;

        & img {
            width: 100%;
            height: 100%;
            display: block;
            transition: transform .4s;
            object-fit: cover;
        }

        &:hover img {
            transform: scale(1.3);
            transform-origin: 50% 50%;
        }
    }
}

.endTimeLabel {
    margin-top: 27px;
}

.repeat {
    margin-top: 10px;
}

.btn-connect {
    color: #000;
}

.pItem {
    .pickDayCheckbox {
        border-radius: 0;
    }

    &:first-child .pickDayCheckbox {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    &:last-child .pickDayCheckbox {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}

.repeatInterval {
    .times {
        flex: 3;
    }

    .selectType {
        flex: 8;
    }
}

.post {
    border-radius: 4px 4px 4px 0px;
    min-height: 200px;
    background: #292120;

    &>.pOverlay {
        border-radius: 4px 4px 4px 0px;
    }
}

.pInfo {
    padding: 10px;

    .title {
        color: white !important;
        font-size: 20px;
        padding-top: 10px;
    }

    & .facilityImage img {
        border-radius: 10px;
        width: 180px;
        height: 180px;
        object-fit: cover;
    }

    .time {
        color: #A7A8A8;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
    }

    & .labelStatus {
        max-width: 120px;
        padding: 4px 0px;
        color: #fff;
        text-align: center;
        border-radius: 4px;
    }

    & .text-powerby {
        color: #928B88;
        margin: 0;
        padding: 0;
        font-size: 13px;
    }

    .labelMedium {
        background: #D59231;
    }

    .labelEmpty {
        background: #2D62AE;
    }

    .labelLarge {
        background: #C0272D;
    }

    .labelNormal {
        background: #3B7E44;
    }

}

.pOverlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 3;
    inset: 0;
}


.custom-multiselect {
    &:deep(.multiselect-wrapper) {
        min-height: auto !important;
    }

    padding: 0.3rem 0rem !important;
}

.v-select :deep(input) {
    &::placeholder {
        color: inherit !important;
        opacity: 0.5;
    }

    font-size: inherit;
    padding: 0.3rem 0.9rem;
}

.roomConsistence {
    color: #fff;
    max-width: 120px;
}

.card-header-fs {
    font-size: 16px;
}

.multiselect.is-disabled :deep() {
    background-color: var(--ms-bg-disabled, #f3f4f6) !important;
}
</style>